@import "../../../../../src/scss/variables";

.customHeaderSafty {
  &.setting_button_ui {
    width: 110px;
    padding: 3px 10px;
  }
}
.modalContainer {
  &.saftyFieldsModal {
    .modalBox {
      .modalInputBox {
        padding: 0px 30px 30px 30px;
      }
      .modalOptionBox {
        width: 100%;
        max-width: 400px;
        margin: 0px;
        .optionTitle {
          font-family: $font-lato;
          font-weight: 600;
          font-size: $font-size-16;
          line-height: 19px;
          text-align: left;
          margin-bottom: 10px;
          color: $shadow-grey;
        }
        .optionListBox {
          margin-top: 10px;
          .overideBtn {
            .containedButton {
              max-width: 200px;
            }
          }
          .optionInnerList {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .optionList {
              width: 80%;
            }
            .actions {
              width: 20%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
}
.inspectionOverRide {
  &.safty {
    .insTyF {
      .insTyFLeft {
        &.insTyFLeftS {
          width: 60%;
        }
      }
      .insTyFRight {
        &.insTyFRightM {
          width: 15%;
        }
        &.insTyFRightD {
          width: 15%;
        }
        &.insTyFRightE {
          width: 10%;
        }
      }
    }
    .insTyBox {
      .insTyList {
        .insTyFLeft {
          &.insTyFLeftS {
            width: 60%;
            .check{
              display: flex;
              .tickbox{
                width: 20px;
                min-width: 20px;
                min-height: 20px;
                position: relative;
                display: block;
              }
              .closeItem{
                position: relative;
                display: block;
                margin-top: 0px;
                margin-left: 5px;
                padding-top: 5px;
              }
            }
          }
        }
        .insTyFRight {
          &.insTyFRightM {
            width: 15%;
          }
          &.insTyFRightD {
            width: 15%;
          }
          &.insTyFRightE {
            width: 10%;
          }
        }
      }
    }
  }
}
.containerBox {
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 4px 50px rgb(0 0 0 / 7%);
  border-radius: 8px;
  padding: 20px;
  .left {
    flex: 1;
    padding: 0px 20px 20px 0px;
    flex-basis: 100%;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    .title {
      width: 100%;
      font-size: 20px;
      font-weight: 500;
      color: $light-primary-text-color;
      margin-bottom: 10px;
    }
    .field {
      background-color: $light-primary-color;
      margin-bottom: 10px;
      padding: 7px 24px 7px 16px;
      color: $light-primary-background-color;
      font-size: 14px;
      margin-right: 10px;
      border-radius: 100px;
      display: flex;
      align-items: center;
      span {
        img {
          margin-top: 3px;
          margin-right: 10px;
        }
      }
    }
  }

  .right {
    flex: 3;
    padding: 0px 0px 20px 0px;

    .innerBox {
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.07);
      background-color: $light-primary-background-color;
      border: 1px solid $Stroke-Fossil-Grey;
      margin-bottom: 20px;
      border-radius: 8px;
      padding: 10px;
      position: relative;
      display: flex;
      .dragIcon {
        svg {
          fill: $light-primary-color;
        }
      }
      .formElements {
        width: 100%;
      }
      .removeSection {
        span {
          font-size: 14px;
          font-weight: 400;
          color: $light-primary-text-color;
          margin-left: 9px;
        }
        .headerRemoveIcon {
          fill: #333333;
        }
        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 10px;
      }
      .overideInputField {
        .inputRoot {
          input {
            padding-bottom: 0px;
          }
        }
      }
    }
    .optionBox {
      margin-top: 10px;
      input {
        &::placeholder {
          font-size: 16px;
          font-weight: 400;
          color: $manatee-color;
        }
      }
      .optionBoxInner {
        padding: 5px 0px;
        border-bottom: 1px solid $concrete-color;
        display: flex;
        .optionRemove {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          .optionRemoveIcon {
            fill: #333333;
          }
        }
        .optionInput {
          padding: 0px;
          border: none;
          margin-bottom: 5px;
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          color: $light-primary-text-color;
          &:focus-visible {
            outline: none;
          }
          &::placeholder {
            font-size: 16px;
            font-weight: 400;
            color: $manatee-color;
          }
        }
      }
    }
    .bottomAction {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;

      .mandatory {
        label {
          span {
            font-size: $font-size-16;
            font-weight: 400;
            color: $light-primary-color;
            font-family: $font-lato;
          }
        }
      }
      .duplicate {
        margin-left: 30px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: $font-size-16;
        font-weight: 400;
        color: $light-primary-color;
        cursor: pointer;
        .headerDuplicateIcon {
          fill: #1b436e;
        }
      }
    }
  }
  .custom-label {
    flex: 1;
    padding: 20px;
  }
}

.formBuilderCustom {
  span {
    font-size: $font-size-16;
    font-weight: 400;
    color: $light-primary-text-color;
    font-family: $font-lato;
    svg {
      fill: $light-primary-color;
    }
  }
}

body {
  &.dark-mode {
    .insTyF .insTyFRight {
      color: $white-color;
    }
    .insTyF .insTyFLeft {
      color: $white-color;
    }

    .dragIcon {
      svg {
        color: $dark-secondary-color;
      }
    }
    .containerBox {
      .right {
        .innerBox {
          background-color: $black-color;
          .dragIcon svg {
            fill: $dark-secondary-color;
          }
          .removeSection {
            span {
              color: $white-color;
            }
            .headerRemoveIcon {
              fill: $dark-secondary-color;
            }
          }
        }
        .bottomAction {
          .mandatory {
            label {
              span {
                color: $dark-secondary-color;
              }
            }
          }
          .duplicate {
            color: $dark-secondary-color;
          }
          .headerDuplicateIcon {
            fill: $dark-secondary-color;
          }
        }
        .optionBox {
          input {
            &::placeholder {
              color: $manatee-color;
            }
          }
          .optionBoxInner {
            .optionRemove {
              cursor: pointer;
              .optionRemoveIcon {
                fill: $dark-secondary-color;
              }
            }
            .optionInput {
              color: $white-color;
              background-color: $black-color;
              &:focus-visible {
                outline: none;
              }
              &::placeholder {
                color: $manatee-color;
                background-color: $black-color;
              }
            }
          }
        }
      }
    }
  }
}