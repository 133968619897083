@import "../../../scss/variables";

.Observationsubmit {
  .overideBtn {
    text-align: end;
    .containedButton {
      max-width: 300px;
      width: 100%;
      font-family: $font-lato;
      font-weight: 600;
    }
  }
}
.obserButton {
  font-family: $font-lato;
  font-weight: 600;
}

.overidePersonal {
  .addShadowBox {
    .radioInline {
      width: 100%;
      display: flex;
      flex-direction: row;

      .overideLables {
        margin-top: 10px;
      }
      .MuiFormGroup-root {
        margin-left: 30px;
      }
    }

    .overideAction {
      .actionContainer {
        width: 148%;
      }
    }
  }
}
